import NotFound from "../components/notFound/NotFound";
import Layout from "../components/shared/layout/Layout";

export default function Custom404() {
    return (
        <Layout>
            <NotFound />
        </Layout>
    );
}
