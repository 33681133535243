import PropTypes from "prop-types";

import colors from "constants/colors";
import IconCheck from "components/shared/svg/IconCheck";

import {
    UspsBlockStyled,
    Content,
    Title,
    Text,
    List,
    Item
} from "./UspsBlock.styled";
import Button from "../button/Button";

export default function UspsBlock({ className }) {
    return (
        <UspsBlockStyled className={className}>
            <Content>
                <Title>Online direct verkrijgbaar</Title>
                <Text>De Lees! Cadeaukaart is altijd online te bestellen.</Text>
                <List>
                    <Item>
                        <IconCheck fill={colors.white} />
                        Boeken, e-books & tijdschriften
                    </Item>
                    <Item>
                        <IconCheck fill={colors.white} />
                        Zowel online als in de winkel in te wisselen
                    </Item>
                    <Item>
                        <IconCheck fill={colors.white} />
                        Op werkdagen voor 17:00 besteld = vandaag verstuurd
                    </Item>
                    <Item>
                        <IconCheck fill={colors.white} />
                        Bedrijven bestellen makkelijk op factuur
                    </Item>
                    <Item>
                        <IconCheck fill={colors.white} />
                        Veilig betalen d.m.v. SSL-encryptie
                    </Item>
                </List>
                <Button
                    element="a"
                    variant="secondary"
                    href="/lees-cadeaukaart-bestellen"
                    rel="noopener noreferrer"
                >
                    Nu online bestellen
                </Button>
            </Content>
        </UspsBlockStyled>
    );
}

UspsBlock.defaultProps = {
    className: undefined
};

UspsBlock.propTypes = {
    className: PropTypes.string
};
