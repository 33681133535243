import dynamic from "next/dynamic";
import { useState } from "react";
import Link from "next/link";
import Button from "components/shared/button/Button";
import Container from "components/shared/container/Container";
import Doubler from "./doubler/Doubler";

import {
    NotFoundStyled,
    Hero,
    Image,
    Heading,
    Description,
    CTA,
    Content,
    UspsBlock
} from "./NotFound.styled";

const ContactFormModal = dynamic(() =>
    import("components/shared/contactForm/modal/Modal")
);

export default function NotFound() {
    const [modalIsOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <NotFoundStyled>
            <Hero>
                <Container>
                    <Image src="/images/404.png" alt="Pagina niet gevonden" />
                    <Heading>
                        Oeps, er mist wat op onze <span>boekenplank</span>...
                    </Heading>
                    <Description>
                        Je kan terug gaan naar de homepagina en van daaruit
                        verder zoeken waar je naar op zoek was of neem contact
                        met ons op wanneer je niet kan vinden wat je zocht.
                    </Description>
                    <CTA>
                        <Link href="/" passHref>
                            <Button element="a">Terug naar de homepage</Button>
                        </Link>
                        <Button variant="ghost" onClick={openModal}>
                            Neem contact op
                        </Button>
                        {modalIsOpen && (
                            <ContactFormModal
                                openModal={modalIsOpen}
                                closeModal={closeModal}
                            />
                        )}
                    </CTA>
                </Container>
            </Hero>
            <Container>
                <Content>
                    <UspsBlock />
                    <Doubler />
                </Content>
            </Container>
        </NotFoundStyled>
    );
}
