import PropTypes from "prop-types";
import Acceptants from "components/shared/acceptants/Acceptants";
import Double from "components/home/double/Double";
import HomeHero from "components/home/homeHero/HomeHero";
import Usps from "components/home/usps/Usps";
import Steps from "components/home/steps/Steps";
import Seo from "components/home/seo/Seo";
import Merchants from "components/home/merchants/Merchants";
import Gift from "components/home/gift/Gift";
import Shops from "components/home/shops/Shops";
import { Above } from "components/home/Home.styled";
import { addApolloState, initializeApollo } from "lib/apolloClient";

import PageLayout from "components/shared/pageLayout/PageLayout";
import Error from "./404";
import { GetLandingPage } from "../helpers/api/cadeauConcepten/queries/landingpage.gql";

export default function Home({ data, slug }) {
    if (!data?.landingPage) {
        return (
            <PageLayout>
                <Error statusCode="404" />
            </PageLayout>
        );
    }

    const currentSlug = data?.page?.slug ?? slug;
    const metadata = data?.landingPage?.metadata ?? data?.page?.metadata;
    const title = metadata?.title ?? "";
    const description = metadata?.description ?? "";
    const keywords = metadata?.keywords ?? [];
    const seo = data?.landingPage?.seo ?? data?.page?.seo;
    const sections = data?.landingPage?.sections;

    return (
        <PageLayout
            slug={currentSlug}
            title={title}
            description={description}
            keywords={keywords}
        >
            <HomeHero hero={data?.landingPage?.hero} />
            <Merchants />
            <Above>
                <Usps items={data?.landingPage?.usps} />
                <Steps items={data?.landingPage?.steps} />
                {sections?.usage && (
                    <Acceptants
                        title={data?.landingPage?.received?.title}
                        description={data?.landingPage?.received?.description}
                        buttons="redeem"
                    />
                )}
                {sections?.business && <Gift />}
            </Above>
            {sections?.resellers && <Shops />}
            {sections?.doubler && <Double />}
            {seo && <Seo title={seo?.seoTitle} text={seo?.seoText} />}
        </PageLayout>
    );
}

Home.defaultProps = {
    data: undefined
};

Home.propTypes = {
    data: PropTypes.shape({
        page: PropTypes.shape({
            title: PropTypes.string,
            slug: PropTypes.string,
            metadata: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                keywords: PropTypes.arrayOf(PropTypes.string)
            }),
            seo: PropTypes.shape({
                seoTitle: PropTypes.string,
                seoText: PropTypes.string
            })
        }),
        landingPage: PropTypes.shape({
            hero: PropTypes.object,
            usps: PropTypes.array,
            steps: PropTypes.array,
            seo: PropTypes.shape({
                seoTitle: PropTypes.string,
                seoText: PropTypes.string
            }),
            metadata: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                keywords: PropTypes.arrayOf(PropTypes.string)
            }),
            received: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string
            }),
            sections: PropTypes.shape({
                resellers: PropTypes.bool,
                doubler: PropTypes.bool
            })
        })
    }),
    slug: PropTypes.string.isRequired
};

export async function getServerSideProps({ params }) {
    const apolloClient = initializeApollo();
    const { slug } = params;

    try {
        const landingPageData = await apolloClient.query({
            query: GetLandingPage,
            variables: { slug },
            context: { timeout: 1000 }
        });
        const data = landingPageData?.data ?? null;
        return addApolloState(apolloClient, { props: { data, slug } });
    } catch (error) {
        console.error(error); // eslint-disable-line
    }

    return { props: { slug } };
}
