import styled from "styled-components";
import media from "constants/media";

export const UspsBlockStyled = styled.div`
    color: var(--color-white);

    background: var(--color-peach);

    ${media.mdUp`
        display: flex;
        width: calc(50% - 10px);

        margin-right: 10px;

        box-shadow: 10px 10px 24px 0 rgba(0,23,75,0.05);
    `}
`;

export const Content = styled.div`
    max-width: 320px;
    margin: 0 auto;

    ${media.mdUp`
        max-width: none;
        margin: 0;
    `}
`;

export const Title = styled.h2`
    margin-bottom: 10px;

    font-size: 25px;
    font-family: var(--font-DINProCondensed);
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;

    ${media.mdUp`
        margin-bottom: 18px;

        font-size: 32px;
        line-height: 40px;
    `}
`;

export const Text = styled.p`
    margin-bottom: 10px;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    ${media.mdUp`
        margin-bottom: 18px;

        font-size: 16px;
        line-height: 25px;
    `}
`;

export const List = styled.ul`
    margin-bottom: 16px;

    list-style: none;

    ${media.mdUp`
        margin-bottom: 24px;
    `}
`;

export const Item = styled.li`
    display: flex;
    align-items: flex-start;

    font-size: 14px;
    line-height: 20px;

    ${media.mdUp`
        font-size: 15px;
        line-height: 25px;
    `}

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    svg {
        width: 14px;
        height: 20px;

        margin-right: 12px;

        ${media.mdUp`
            height: 25px;
        `}
    }
`;
