import Link from "next/link";
import Button from "components/shared/button/Button";
import { useMediaQueryContext } from "lib/mediaQueryContext";

import {
    DoublerStyled,
    Content,
    Top,
    Cards,
    Title,
    Text,
    Background
} from "./Doubler.styled";

export default function Double() {
    const { desktopView, mobileView } = useMediaQueryContext();

    return (
        <DoublerStyled>
            <Content>
                <Top>
                    {mobileView && (
                        <Cards
                            src="/images/doubler-mobile.png"
                            alt="Verdubbelaar"
                            loading="lazy"
                        />
                    )}
                    {desktopView && (
                        <Cards
                            src="/images/doubler-desktop.webp"
                            alt="Verdubbelaar"
                            loading="lazy"
                        />
                    )}
                </Top>
                <div>
                    <Title>
                        <strong>Verdubbel</strong> jouw cadeaukaart waarde
                    </Title>
                    <Text>
                        Maak kans op een verdubbeling van de waarde op je Lees!
                        Cadeau gift card. Vul meteen je kaartnummer in en doe
                        mee!
                    </Text>
                    <Link href="/verdubbelaar" passHref>
                        <Button element="a">Doe mee</Button>
                    </Link>
                </div>
                <Background />
            </Content>
        </DoublerStyled>
    );
}
