import styled from "styled-components";
import media from "constants/media";

import Usps from "components/shared/uspsBlock/UspsBlock";

export const NotFoundStyled = styled.div`
    ${media.mdUp`
        padding-bottom: 130px;
    `};
`;

export const Hero = styled.div`
    padding: 60px 0;

    text-align: center;

    background-color: var(--color-darkNight);

    ${media.mdUp`
        padding-bottom: 250px;
    `};
`;

export const Image = styled.img`
    width: 100%;
    max-width: 848px;
    margin-left: 5%;

    ${media.mdUp`
        margin-left: 80px;
    `};
`;

export const Heading = styled.h1`
    margin: 0 auto;
    padding: 20px 0;

    color: var(--color-white);
    font-weight: normal;
    font-size: 35px;
    line-height: 40px;
    text-align: center;

    span {
        font-family: var(--font-Agne);
    }

    ${media.mdUp`    
        max-width: 780px;
        font-size: 64px;
        line-height: 70px;
    `};
`;

export const Description = styled.p`
    margin: 0 auto;

    color: var(--color-white);
    font-size: 18px;
    font-family: var(--font-DINPro);
    line-height: 25px;
    text-align: center;

    ${media.mdUp`
        max-width: 580px;
        font-size: 20px;
        line-height: 30px;
    `};
`;

export const CTA = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a,
    button {
        margin-top: 24px;
    }

    ${media.mdUp`
        flex-direction: row;

        a {
            :first-of-type {
                margin-right: 40px;
            }
        }
    `};
`;

export const Content = styled.div`
    margin: 0 -10px;

    ${media.mdUp`
        display: flex;
        margin: -120px auto 0;
        padding: 0 20px;
    `};
`;

export const UspsBlock = styled(Usps)`
    padding: 45px 20px;

    ${media.mdUp`
        padding: 52px 60px 58px;
    `}
`;
