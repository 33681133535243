import styled from "styled-components";
import media from "constants/media";

export const DoublerStyled = styled.div`
    position: relative;
    z-index: 1;

    max-width: 480px;
    margin: 0 auto;
    padding: 50px 20px;

    ${media.mdUp`
        width: calc(50% - 10px);
        margin: 0 0 0 10px;
        padding: 0;
    `}
`;

export const Content = styled.div`
    position: relative;

    text-align: center;
`;

export const Top = styled.div`
    margin-bottom: 10px;

    ${media.mdUp`
        margin-top: -54px;
        margin-bottom: 0;
    `}
`;

export const Cards = styled.img`
    display: block;
    width: 100%;
    max-width: 280px;

    margin: 0 auto;

    ${media.mdUp`
        width: initial;
        max-width: 400px;
        margin: 0;
    `}
`;

export const Title = styled.h2`
    margin-bottom: 14px;

    font-weight: normal;
    font-size: 35px;
    line-height: 40px;

    strong {
        font-weight: 500;
        font-family: var(--font-Agne);
    }

    ${media.mdUp`
        margin-bottom: 4px;
        
        font-family: var(--font-DINProCondensed);
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 1px;
        text-transform: uppercase;

        strong {
            font-family: var(--font-DINProCondensed);
        }
    `}
`;

export const Text = styled.p`
    margin-bottom: 24px;

    font-weight: normal;
    font-size: 18px;
    line-height: 25px;

    ${media.mdUp`
        margin-bottom: 14px;

        font-size: 20px;
        line-height: 30px;
    `}
`;

export const Background = styled.div`
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: -1;

    width: 280px;
    height: 250px;

    background: url("/images/doubler-x2.svg") no-repeat top center;
    background-size: 280px 250px;

    transform: translate(-50%, -50%);

    ${media.mdUp`
        top: 140px;
        left: 256px;
        width: 177px;
        height: 158px;
        
        background-size: 177px 158px;
        
        transform: none;
    `}
`;
